<template>
  <!-- eslint-disable -->
  <div class="forecast-section section-wrapper">
    <!-- forecast-heading-wrapper in desktop version  -->
    <div v-if="!isMobile">
      <div class="forecast-heading-super-wrapper">
        <div class="new-forecast-heading-wrapper">
          <p class="new-forecast-address" style="margin-bottom: 0;">
            <span class="mdi mdi-map-marker"></span>
            {{ getAddress1 }} {{ getAddress2 }}
          </p>

          <div
            v-if="forecastAccess"
            style="display: flex; align-items: center; cursor: pointer;"
            @click="toggleEmailPopup(true)"
          >
            <button class="download-btn" style="width: 22rem;">
              Download Report
            </button>
          </div>

          <div
            v-if="
              !isCognitoUserLoggedIn || (!forecastAccess && !subscriptionFlag)
            "
          >
            <button
              class="bg-primary forecast-subscription-btn"
              @click="showSubscriptionPopup()"
            >
              Subscribe for Access to Full Property Report
            </button>
          </div>
          <div v-else-if="subscriptionFlag && !forecastAccess">
            <button
              @click="showSubscriptionPopup()"
              class="bg-primary"
              style="margin-bottom: 15px;"
            >
              Purchase for $2.99
            </button>
          </div>
        </div>
      </div>
      <!-- /forecast-heading-wrapper -->

      <!-- forecast-content-wrapper -->
      <div class="forecast-content-wrapper flex-wrapper">
        <!-- forecast-property-img -->
        <div
          v-if="isListHub && getListhubPhotoCount > 0"
          class="forecast-property-img-wrapper"
        >
          <!-- when it is desktop resolution -->
          <v-carousel
            class="listing-carousel"
            height="auto"
            :show-arrows-on-hover="false"
            :hide-delimiters="true"
            :hide-delimiter-background="true"
          >
            <v-carousel-item v-for="i in getListhubPhotoCount" :key="i">
              <div
                class="forecast-property-listhub-img"
                :style="
                  'background-position: center;background-repeat: no-repeat;background-size: height:390px cover; background-image: url(' +
                    getListhubPhotoURL(i) +
                    ')'
                "
              ></div>
            </v-carousel-item>
          </v-carousel>
        </div>
        <!-- when the image is only the google map or honely -->
        <div v-else class="forecast-property-img-wrapper">
          <div
            class="forecast-property-img"
            v-bind:style="[
              validImage
                ? { background: 'url(' + image + ')' }
                : { background: '' },
            ]"
          >
            <div class="property-fav-btn" @click="toggleFavorite(property)">
              <span v-if="isFavorite" class="mdi mdi-heart"></span>
              <span v-else class="mdi mdi-heart-outline"></span>
            </div>
          </div>
        </div>
        <!-- /forecast-property-img -->

        <!-- forecast-property-data Desktop Version-->
        <div class="forecast-data">
          <p class="new-forecast-section-title">Property Details</p>

          <!-- honely-forecast-property-data -->
          <div class="honely-forecast-property-data">
            <div class="property-data">
              <span>{{ getPropertyBeds }}</span
              >| <span>{{ getPropertyBaths }}</span
              >|
              <span>{{ getPropertySqft }}</span>
              |
              <span
                ><router-link
                  :to="{
                    name: 'ListingId',
                    params: {
                      search_id: forecast.property_forecast.property_id,
                    },
                  }"
                  class="property-link-btn"
                >
                  View More
                </router-link></span
              >
            </div>
          </div>

          <!-- calculator -->
          <div class="property-options">
            <!-- <div
          class="property-options-item"
          :class="subscriptionFlag ? '' : 'honely-forecast-not-subscribed'"
          @click="showForecastReportModal"
        >
          <span class="mdi mdi-file-chart"></span>
          <p>Generate Report</p>
        </div> -->

            <div
              class="property-options-item"
              @click="showHonelyCalculatorModal"
            >
              <span
                class="mdi mdi-calculator"
                style="font-size: 2.8rem; color: #a9a9a9"
              ></span>
              <p
                style="font-weight: 400; font-size: 1rem; line-height: 1.5rem;"
              >
                Update estimate with new information
              </p>
            </div>
          </div>

          <p class="new-forecast-section-title" style="margin-top: 1rem;">
            Honely Estimated Value
          </p>
          <p class="forecast-value" style="position: relative;">
            <span>
              {{ getHonelyEstimate }}
              <span
                class="forecast-value-icon"
                style="position: absolute; bottom: 1rem;"
              >
                <!-- <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <span
                      class="mdi mdi-information"
                      v-on="on"
                      style="font-size: 1.2rem; color: #CBCBCB;"
                    ></span>
                  </template>
                  <div class="forecast-value-notes">
                    <span> Estimated range</span>
                    <span style="color: white; font-size: 1rem;">
                      {{ getHonelyEstimateRange }}
                    </span>
                  </div>
                </v-tooltip> -->
              </span>
            </span>
            <span class="forecast-value-notes">
              <span style="font-size: 1.375rem; padding-right: 0.5rem;">
                Estimated range</span
              >
              <span style="font-size: 1.375rem; ">
                {{ getHonelyEstimateRange ? getHonelyEstimateRange : "--" }}
              </span>
            </span>
          </p>

          <!-- /honely-forecast-property-data -->

          <!-- /honely-forecast-container -->
        </div>

        <!-- /forecast-property-data -->
      </div>
    </div>
    <!-- /forecast-content-wrapper -->

    <!-- forecast-heading-wrapper in mobile version  -->
    <div v-else>
      <div class="forecast-heading-super-wrapper">
        <div class="new-forecast-heading-wrapper">
          <p class="new-forecast-address">
            <span class="mdi mdi-map-marker"></span>
            {{ getAddress1 }} {{ getAddress2 }}
          </p>
        </div>
      </div>
      <!-- /forecast-heading-wrapper -->

      <!-- forecast-content-wrapper -->
      <div class="forecast-content-wrapper flex-wrapper">
        <!-- forecast-property-img -->
        <div
          v-if="isListHub && getListhubPhotoCount > 0"
          class="forecast-property-img-wrapper"
        >
          <!-- when it is mobile resolution -->
          <v-carousel
            class="listing-carousel"
            height="auto"
            :show-arrows="false"
            :hide-delimiters="true"
            :hide-delimiter-background="true"
          >
            <v-carousel-item>
              <div
                class="mobile-forecast-property-listhub-img"
                :style="
                  'background-position: center;background-repeat: no-repeat;background-size: cover; background-image: url(' +
                    getListhubPhotoURL(1) +
                    ')'
                "
                @click="toggleFullScreenImage()"
              ></div>
            </v-carousel-item>
          </v-carousel>

          <!-- only trigger when it is mobile and the listhub image is click -->
          <v-dialog v-model="showDialog" fullscreen>
            <v-card class="listing-full-screen">
              <v-toolbar dark color="primary">
                <v-btn icon white @click="showDialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-carousel
                class="listing-carousel"
                height="auto"
                :show-arrows-on-hover="true"
                :hide-delimiters="true"
                :hide-delimiter-background="true"
                style="height: 100% !important;"
              >
                <v-carousel-item
                  v-for="i in getListhubPhotoCount"
                  :key="i"
                  style="height: 100%;"
                >
                  <div
                    class="forecast-property-listhub-img"
                    :style="
                      'background-size: 100%; background-image: url(' +
                        getListhubPhotoURL(i) +
                        ')'
                    "
                    style="height:  100%;"
                  ></div>
                </v-carousel-item>
              </v-carousel>
            </v-card>
          </v-dialog>
        </div>
        <!-- when the image is only the google map or honely -->
        <div v-else class="forecast-property-img-wrapper">
          <div
            class="mobile-forecast-property-img"
            v-bind:style="[
              validImage
                ? { background: 'url(' + image + ')' }
                : { background: '' },
            ]"
            @click="isGoogleImage() && toggleFullScreenImage()"
          >
            <div class="property-fav-btn" @click="toggleFavorite(property)">
              <span v-if="isFavorite" class="mdi mdi-heart"></span>
              <span v-else class="mdi mdi-heart-outline"></span>
            </div>
          </div>

          <!-- full screen version when the google map is show and is mobile ver -->
          <v-dialog v-model="showDialog" fullscreen>
            <v-card class="listing-full-screen">
              <v-toolbar dark color="primary">
                <v-btn icon white @click="showDialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <div
                class="forecast-property-img google-image-full-screen"
                v-bind:style="[
                  validImage
                    ? { background: 'url(' + image + ')' }
                    : { background: '' },
                ]"
              ></div>
            </v-card>
          </v-dialog>
        </div>
        <!-- /forecast-property-img -->

        <!-- forecast-property-data Desktop Version-->
        <div class="forecast-data">
          <p class="new-forecast-section-title">Property Details</p>

          <!-- honely-forecast-property-data -->
          <div class="honely-forecast-property-data">
            <div class="property-data">
              <span>{{ getPropertyBeds }}</span
              >| <span>{{ getPropertyBaths }}</span
              >|
              <span>{{ getPropertySqft }}</span>
            </div>

            <!-- calculator -->

            <v-btn
              icon
              @click="showHonelyCalculatorModal"
              class="mobile-calculator-version"
            >
              <img
                :src="mobileCalculatorPNG"
                alt="calculator"
                style="padding: 0.5rem;"
              />
            </v-btn>
          </div>

          <p class="new-forecast-section-title" style="margin-top: 1rem;">
            Honely Estimated Value
          </p>
          <div class="mobile-forecast-value-container">
            <p class="forecast-value" style="position: relative;">
              <span>
                {{ getHonelyEstimate }}
                <span
                  class="forecast-value-icon"
                  style="position: absolute; bottom: 1rem;"
                >
                  <!-- <v-tooltip right>
                    <template v-slot:activator="{ on }">
                      <span
                        class="mdi mdi-information"
                        v-on="on"
                        style="font-size: 1.2rem; color: #CBCBCB;"
                      ></span>
                    </template>
                    <div class="forecast-value-notes">
                      <span> Estimated range</span>
                      <span style="color: white; font-size: 1rem;">
                        {{ getHonelyEstimateRange }}
                      </span>
                    </div>
                  </v-tooltip> -->
                </span>
              </span>
              <span class="forecast-value-notes">
                <span style="font-size: 0.875rem; padding-right: 0.5rem;">
                  Estimated range</span
                >
                <span style="font-size: 0.875rem;">
                  {{ getHonelyEstimateRange ? getHonelyEstimateRange : "--" }}
                </span>
              </span>
            </p>
            <!-- <button>
              Current
            </button> -->
          </div>

          <!-- /honely-forecast-property-data -->

          <!-- /honely-forecast-container -->
        </div>

        <!-- /forecast-property-data -->
      </div>
    </div>

    <!-- /forecast-content-wrapper -->

    <!-- honely forecast data  -->
    <div class="honely-forecast-timeframe-container">
      <p class="new-forecast-section-title">
        Honely Forecast
      </p>
      <!-- honely-forecast-container -->
      <div class="honely-forecast-container" v-if="!isMobile">
        <!-- new style of show forecast -->
        <div class="honely-forecast-container-desktop">
          <!-- current -->
          <!-- <div class="honely-forecast-content">
            <span class="honely-forecast-name">Current</span>
            <span class="honely-forcast-price"
              >${{ this.forecastDataObj[0].value }}</span
            >
            <div>
              <span
                :style="
                  this.forecastDataObj[0].percentage &&
                    (this.forecastDataObj[0].percentage !== '--'
                      ? this.forecastDataObj[0].percentage > 0
                        ? 'color: green;'
                        : 'color: red'
                      : '')
                "
                >({{ this.forecastDataObj[0].percentage }}%)</span
              >
            </div>
          </div> -->
          <!-- 3 month -->
          <div class="honely-forecast-content">
            <span class="honely-forecast-name">3 Month</span>
            <span class="honely-forcast-price"
              >${{ this.forecastDataObj[1].value }}</span
            >

            <div>
              <span
                :style="
                  this.forecastDataObj[1].percentage &&
                    (this.forecastDataObj[1].percentage !== '--'
                      ? this.forecastDataObj[1].percentage > 0
                        ? 'color: green;'
                        : 'color: red'
                      : '')
                "
              >
                {{ getForcastDiffDesktopVer(1) }}
              </span>
              <span
                :style="
                  this.forecastDataObj[1].percentage &&
                    (this.forecastDataObj[1].percentage !== '--'
                      ? this.forecastDataObj[1].percentage > 0
                        ? 'color: green;'
                        : 'color: red'
                      : '')
                "
                >({{ this.forecastDataObj[1].percentage }}%)</span
              >
            </div>
          </div>
          <!-- 1 year -->
          <div
            class="honely-forecast-content"
            :class="
              // (!isCognitoUserLoggedIn ||
              //   (!forecastAccess && !subscriptionFlag)) &&
              (!isCognitoUserLoggedIn && isRestrictedSection('1 Year')) ||
              (isCognitoUserLoggedIn &&
                !forecastAccess &&
                isRestrictedSection('1 Year'))
                ? 'blocked'
                : ''
            "
          >
            <span class="honely-forecast-name">1 Year</span>
            <span class="honely-forcast-price"
              >${{
                this.forecastDataObj[2].value !== "--"
                  ? this.forecastDataObj[2].value
                  : this.forecastDataObj[1].value
              }}</span
            >

            <div>
              <span
                :style="
                  this.forecastDataObj[2].percentage &&
                    (this.forecastDataObj[2].percentage !== '--'
                      ? this.forecastDataObj[2].percentage > 0
                        ? 'color: green;'
                        : 'color: red'
                      : '')
                "
              >
                {{
                  getForcastDiffDesktopVer(2) !== ``
                    ? getForcastDiffDesktopVer(2)
                    : getForcastDiffDesktopVer(1)
                }}
              </span>
              <span
                :style="
                  this.forecastDataObj[2].percentage &&
                    (this.forecastDataObj[2].percentage !== '--'
                      ? this.forecastDataObj[2].percentage > 0
                        ? 'color: green;'
                        : 'color: red'
                      : '')
                "
                >({{
                  this.forecastDataObj[2].percentage !== "--"
                    ? this.forecastDataObj[2].percentage
                    : this.forecastDataObj[1].percentage
                }}%)</span
              >
            </div>
          </div>
          <!-- 2 year -->
          <div
            class="honely-forecast-content"
            :class="
              // (!isCognitoUserLoggedIn ||
              //   (!forecastAccess && !subscriptionFlag)) &&
              (!isCognitoUserLoggedIn && isRestrictedSection('2 Years')) ||
              (isCognitoUserLoggedIn &&
                !forecastAccess &&
                isRestrictedSection('2 Years'))
                ? 'blocked'
                : ''
            "
          >
            <span class="honely-forecast-name">2 Years</span>
            <span class="honely-forcast-price"
              >${{
                this.forecastDataObj[3].value !== "--"
                  ? this.forecastDataObj[3].value
                  : this.forecastDataObj[1].value
              }}</span
            >

            <div>
              <span
                :style="
                  this.forecastDataObj[3].percentage &&
                    (this.forecastDataObj[3].percentage !== '--'
                      ? this.forecastDataObj[3].percentage > 0
                        ? 'color: green;'
                        : 'color: red'
                      : '')
                "
              >
                {{
                  getForcastDiffDesktopVer(3) !== ``
                    ? getForcastDiffDesktopVer(3)
                    : getForcastDiffDesktopVer(1)
                }}
              </span>
              <span
                :style="
                  this.forecastDataObj[3].percentage &&
                    (this.forecastDataObj[3].percentage !== '--'
                      ? this.forecastDataObj[3].percentage > 0
                        ? 'color: green;'
                        : 'color: red'
                      : '')
                "
                >({{
                  this.forecastDataObj[3].percentage !== "--"
                    ? this.forecastDataObj[3].percentage
                    : this.forecastDataObj[1].percentage
                }}%)</span
              >
            </div>
          </div>
          <!-- 3 year -->
          <div
            class="honely-forecast-content"
            :class="
              // (!isCognitoUserLoggedIn ||
              //   (!forecastAccess && !subscriptionFlag)) &&
              (!isCognitoUserLoggedIn && isRestrictedSection('3 Years')) ||
              (isCognitoUserLoggedIn &&
                !forecastAccess &&
                isRestrictedSection('3 Years'))
                ? 'blocked'
                : ''
            "
          >
            <span class="honely-forecast-name">3 Years</span>
            <span class="honely-forcast-price"
              >${{
                this.forecastDataObj[4].value !== "--"
                  ? this.forecastDataObj[4].value
                  : this.forecastDataObj[1].value
              }}</span
            >

            <div>
              <span
                :style="
                  this.forecastDataObj[4].percentage &&
                    (this.forecastDataObj[4].percentage !== '--'
                      ? this.forecastDataObj[4].percentage > 0
                        ? 'color: green;'
                        : 'color: red'
                      : '')
                "
              >
                {{
                  getForcastDiffDesktopVer(4) !== ``
                    ? getForcastDiffDesktopVer(4)
                    : getForcastDiffDesktopVer(1)
                }}
              </span>
              <span
                :style="
                  this.forecastDataObj[4].percentage &&
                    (this.forecastDataObj[4].percentage !== '--'
                      ? this.forecastDataObj[4].percentage > 0
                        ? 'color: green;'
                        : 'color: red'
                      : '')
                "
                >({{
                  this.forecastDataObj[4].percentage !== "--"
                    ? this.forecastDataObj[4].percentage
                    : this.forecastDataObj[1].percentage
                }}%)</span
              >
            </div>
          </div>
        </div>

        <div
          style="display:flex; text-align: center; justify-content: center; align-items: center; margin-top: 2rem;"
          :class="
            // (!isCognitoUserLoggedIn ||
            //   (!forecastAccess && !subscriptionFlag)) &&
            isCognitoUserLoggedIn && forecastAccess
              ? 'honely-forecast-button-hidden'
              : ''
          "
        >
          <button
            class="bg-primary"
            @click="showSubscriptionPopup()"
            style="margin:1.5rem 0; width: 22em;"
          >
            Access Full Forecast Report
          </button>
        </div>
      </div>

      <!-- dropdwon version whenn it is mobile resoultion -->
      <div class="mobile-honely-forecast-container" v-else>
        <div class="honely-property-position-relative" style="width:70%">
          <!-- overlay part when not subscript yet  -->

          <div
            v-if="
              // (!isCognitoUserLoggedIn ||
              //   (!forecastAccess && !subscriptionFlag)) &&
              (!isCognitoUserLoggedIn &&
                isRestrictedSection(activeForecastTimeframeText)) ||
                (isCognitoUserLoggedIn &&
                  !forecastAccess &&
                  isRestrictedSection(activeForecastTimeframeText))
            "
            class="container-overlay"
            style="background: none"
          >
            <div
              class="overlay-wrapper"
              style="align-items: baseline; text-align: start;"
            >
              <div
                v-if="
                  !isCognitoUserLoggedIn ||
                    (!forecastAccess && !subscriptionFlag)
                "
              >
                <button
                  class="bg-primary"
                  style="font-size:0.8rem; margin-left: 1rem;"
                  @click="showSubscriptionPopup()"
                >
                  Subscribe
                </button>
              </div>
            </div>
          </div>

          <div
            class="mobile-honely-forecast-tab-content"
            :class="
              // (!isCognitoUserLoggedIn ||
              //   (!forecastAccess && !subscriptionFlag)) &&
              (!isCognitoUserLoggedIn &&
                isRestrictedSection(activeForecastTimeframeText)) ||
              (isCognitoUserLoggedIn &&
                !forecastAccess &&
                isRestrictedSection(activeForecastTimeframeText))
                ? 'blocked'
                : ''
            "
          >
            <!-- title, in case need to show in future design -->
            <!-- <div>
                {{ activeForecastTimeframeText }}
              </div> -->

            <!-- property value info -->
            <div style="font-size: 2rem;">
              <span style="color:black; font-weight: 500;">{{
                activeForecastValue !== "--"
                  ? activeForecastValue
                  : `$${this.forecastDataObj[1].value}`
              }}</span>
            </div>

            <!-- percentage and diff info -->
            <div
              :style="
                activeForecastPercent &&
                  (activeForecastPercent.split(' ')[1] !== '--'
                    ? activeForecastPercent.split(' ')[1] > 0
                      ? 'color: green;'
                      : 'color: red'
                    : '')
              "
              style="font-size: 0.875rem"
            >
              <span style="margin:0 0.5rem; ">{{
                getForecastDiffText(activeForecastValue) !== 0 &&
                getForecastDiffText(activeForecastValue) !== ``
                  ? getForecastDiffText(activeForecastValue).text
                  : getForcastDiffDesktopVer(1)
              }}</span>

              <span
                >({{
                  activeForecastPercent &&
                    (activeForecastPercent.split(" ")[1] !== "--"
                      ? activeForecastPercent.split(" ")[1]
                      : this.forecastDataObj[1].percentage)
                }}%)</span
              >
            </div>
          </div>
        </div>

        <div>
          <v-menu v-model="menu" :close-on-content-click="false" offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                v-on="on"
                :rounded="true"
                class="forecast-options "
                style="border: 1px solid #D7DBE8; max-width: 140px;"
              >
                <span class="mr-2">{{
                  selectedForecast ? selectedForecast.text : "Select an option"
                }}</span>
                <v-icon
                  style="position: absolute; right: 0; transform: translateX(20px);"
                >
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(timeframe, index) in forecastIntervals"
                :key="index"
                :value="timeframe"
                v-on:click="selectOption(timeframe)"
              >
                <v-list-item-title>{{ timeframe.text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </div>

    <!-- honely-property-zip-data -->
    <div class="honely-property-zip-data">
      <div class="new-forecast-section-title">
        Compare it to other properties in zip code {{ getZipcode }}
      </div>
      <div class="honely-property-position-relative">
        <!-- <div v-if="!isCognitoUserLoggedIn" class="container-overlay">
        <div class="overlay-wrapper">
          <p>Message prompting to login</p>
          <button class="bg-primary">Sign In</button>
        </div>
      </div> -->
        <div
          v-if="!isCognitoUserLoggedIn || !forecastAccess"
          class="container-overlay"
        >
          <div class="overlay-wrapper">
            <div
              v-if="
                !isCognitoUserLoggedIn || (!forecastAccess && !subscriptionFlag)
              "
            >
              <p>Please subscribe to view all of our statistics</p>
              <button class="bg-primary" @click="showSubscriptionPopup()">
                Subscribe
              </button>
            </div>
            <div v-else-if="subscriptionFlag && !forecastAccess">
              <p>Please purchase $2.99 to view this statistics</p>
              <button @click="showSubscriptionPopup()" class="bg-primary">
                Purchase for $2.99
              </button>
            </div>
          </div>
        </div>
        <div
          class="honely-property-zip-data-charts"
          :class="!isCognitoUserLoggedIn || !forecastAccess ? 'blocked' : ''"
        >
          <div class="chart-container">
            <p class="chart-tilte">
              <span class="mdi mdi-chart-bar"></span>
              Subject Property v. Similar Sized Properties
            </p>
            <p>
              The average {{ getAvgHomesizeRange }} sqft property value:
              <span class="forecast-value">{{ getAvgValueByHomesize }}</span>
            </p>
            <p>
              This property is valued at:
              <span class="forecast-value">{{ getAvgPercentByHomesize }}</span>
            </p>
            <div class="chart-wrapper">
              <apexchart
                width="100%"
                height="50px"
                :options="chartOptions"
                :series="propertyPercentBySizeData"
              />
            </div>
          </div>
          <div class="chart-container">
            <p class="chart-tilte">
              <span class="mdi mdi-chart-bar"></span>
              Price per SQft vs. Average Property
            </p>
            <p>
              The average price per SQft:
              <span class="forecast-value">{{ getAvgValuePerSqftInZip }}</span>
            </p>
            <p>
              This property's Price Per SQft:
              <span class="forecast-value">{{
                getPropertyPercentPerSqftInZip
              }}</span>
            </p>
            <div class="chart-wrapper">
              <apexchart
                width="100%"
                height="50px"
                :options="chartOptions"
                :series="propertyPricePerSqftData"
              />
            </div>
          </div>
          <div class="chart-container">
            <p class="chart-tilte">
              <span class="mdi mdi-crosshairs-gps"></span>
              Subject Property v. Properties
            </p>
            <p>
              The average property value:
              <span class="forecast-value">{{ getAvgPropertyValueByZip }}</span>
            </p>
            <p>
              This property is valued at:
              <span class="forecast-value">{{
                getPropertyPercentageByZip
              }}</span>
            </p>
            <div class="chart-wrapper">
              <apexchart
                width="100%"
                height="50px"
                :options="chartOptions"
                :series="propertyPercentByZipData"
              />
            </div>
          </div>
          <div class="chart-container">
            <p class="chart-tilte">
              <span class="mdi mdi-chart-bar"></span>
              Subject Property v. {{ getPropertyBeds }} Properties
            </p>
            <p>
              The average {{ getPropertyBeds }} property value:
              <span class="forecast-value">{{
                getAvgPropertyValueByBeds
              }}</span>
            </p>
            <p>
              This property is valued at:
              <span class="forecast-value">{{ getPropertyPercentByBeds }}</span>
            </p>
            <div class="chart-wrapper">
              <apexchart
                width="100%"
                height="50px"
                :options="chartOptions"
                :series="propertyPercentByBedsData"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- only show when it is mobile version  -->
    <div
      style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-top:1.5rem"
    >
      <div
        v-if="forecastAccess && isMobile"
        style="display: flex; align-items: center; cursor: pointer;"
        @click="toggleEmailPopup(true)"
      >
        <button class="download-btn" style="width: 22rem;">
          Download Report
        </button>
      </div>

      <span v-if="isMobile"
        ><router-link
          :to="{
            name: 'ListingId',
            params: {
              search_id: forecast.property_forecast.property_id,
            },
          }"
          class="property-link-btn"
        >
          View More Property Information
        </router-link></span
      >
    </div>
    <!-- /only show when it is mobile version  -->

    <!-- /honely-property-zip-data -->
    <subscription-popup
      :show="showSubscription"
      :forecastAccess="forecastAccess"
      :propertyId="getPropertyId"
      @toggleShow="toggleSubscriptionShow"
    />
    <single-subscription-popup
      :show="showSingleSubscription"
      :forecastAccess="forecastAccess"
      :propertyId="getPropertyId"
      :defaultPaymethod="defaultPaymethod"
      @toggleShow="toggleSingleSubscriptionShow"
    />
    <email-popup
      :show="showEmailPopup"
      @toggleShow="toggleEmailPopup"
      :cognitoUser="this.cognitoUser"
      :property="this.property"
    />
    <login-popup :show="showLogin" @toggleShow="toggleLoginPopupShow" />
  </div>
  <!-- eslint-enable -->
</template>

<script>
/* eslint-disable */
import axios from "axios";
import { mapGetters, mapState } from "vuex";

export default {
  name: "HonelyForeast",
  props: {
    forecast: Object,
    property: Object,
    propertyZipData: Object,
    subscriptionFlag: Boolean,
    defaultPaymethod: Object,
    address: String,
  },
  components: {
    SubscriptionPopup: () => import("@/components/forecast/SubscriptionPopup"),
    SingleSubscriptionPopup: () =>
      import("@/components/forecast/SingleSubscriptionPopup"),
    EmailPopup: () => import("@/components/email_popup/Index"),
    LoginPopup: () => import("@/components/login_popup/Index"),
  },
  data() {
    return {
      // blocked: true,
      menu: false,
      selectedForecast: { text: "3 Month", value: 1 },
      forecastIntervals: [
        { text: "3 Month", value: 1 },
        { text: "1 Year", value: 2 },
        { text: "2 Years", value: 3 },
        { text: "3 Years", value: 4 },
      ],
      mobileCalculatorPNG: require("@/assets/site_images/icons/modifier.png"),
      forecastDataObj: {
        0: {},
        1: {},
        2: {},
        3: {},
        4: {},
      },
      isMobile: false,
      currentPage: 0,
      showDialog: false,
      image: null,
      validImage: false,
      activeForecastStatus: "",
      activeForecastTimeframe: 1,
      // activeForecastTimeframeText: this.isCognitoUserLoggedIn
      //   ? "Three Month"
      //   : "Current",
      activeForecastTimeframeText: "3 Month",
      activeForecastPercent: 0,
      activeForecastValue: 0,
      forecastPercentageChange: [],
      forecastValueChange: [],
      forecastTimeframe: ["Current", "3 Month", "1 Year", "2 Years", "3 Years"],
      showSubscription: false,
      showSingleSubscription: false,
      showEmailPopup: false,
      showLogin: false,
    };
  },
  computed: {
    ...mapState("auth", ["cognitoUser"]),
    ...mapGetters("listings", ["favoriteListings"]),
    ...mapGetters("auth", [
      "loggedIn",
      "username",
      "vxAuth",
      "vxAuthDependent",
      "isCognitoUserLoggedIn",
      "cognitoUser",
    ]),

    forecastDiffStyle(data = this.activeForecastValue) {
      const color = this.getForecastDiffText(data).color;
      console.log(color);
      return { color: color };
    },
    isListHub() {
      if (this.forecast.property_forecast.listing_key !== null) {
        return true;
      } else {
        return false;
      }
    },
    getListhubPhotoCount() {
      let count = 0;
      if (this.forecast.property_forecast.photos_count > 5) {
        count = 5;
      } else {
        count = parseInt(this.forecast.property_forecast.photos_count);
      }
      console.log("count: " + count);
      return count;
    },
    isFavorite() {
      let found = false;
      if (this.property) {
        if (this.favoriteListings && this.favoriteListings.length > 0) {
          for (let i = 0; i < this.favoriteListings.length; i++) {
            if (
              this.favoriteListings[i].property_id ===
                this.property.address.property_id ||
              this.favoriteListings[i].apn === this.property.address.apn
            ) {
              found = true;
              break;
            }
          }
        }
      }
      return found;
    },
    getAddress1() {
      if (this.property) {
        if (this.property.address.full_address) {
          if (
            this.property.address.city &&
            this.property.address.state &&
            this.property.address.zip_code
          ) {
            const toRemove =
              this.capitalize(this.property.address.city) +
              " " +
              this.property.address.state +
              " " +
              this.property.address.zip_code;
            return (
              this.property.address.full_address.replace(toRemove, "").trim() +
              ", "
            );
          } else {
            return this.property.address.full_address;
          }
        } else {
          return "--";
        }
      } else {
        return "--";
      }
    },
    getAddress2() {
      let address2 = "";
      if (this.property) {
        if (this.property.address.city) {
          address2 += this.capitalize(this.property.address.city) + " ";
        }
        if (this.property.address.state) {
          address2 += this.property.address.state + " ";
        }
        if (this.property.address.zip_code) {
          address2 += this.property.address.zip_code;
        }
      }
      return address2;
    },
    getZipcode() {
      if (this.forecast) {
        if (this.forecast.zipcode) {
          return this.forecast.zipcode;
        } else {
          return "--";
        }
      } else {
        return "--";
      }
    },
    isOnSale() {
      if (this.property) {
        if (this.forecast && this.forecast.property_forecast) {
          if (this.forecast.property_forecast.property_status) {
            if (
              this.property_forecast.property_forecast.property_status ===
                "Active" ||
              this.property_forecast.property_forecast.property_status ===
                "Sale"
            ) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    getListPrice() {
      if (this.forecast && this.forecast.property_forecast) {
        if (this.forecast.property_forecast.list_price) {
          return this.formatNumber(
            this.forecast.property_forecast.list_price,
            true
          );
        } else {
          return "--";
        }
      } else {
        return "--";
      }
    },
    getHonelyEstimate() {
      if (this.forecast && this.forecast.property_forecast) {
        if (this.forecast.property_forecast.appraisal) {
          return this.formatNumber(
            this.forecast.property_forecast.appraisal,
            true
          );
        } else {
          return "--";
        }
      } else {
        return "--";
      }
    },
    getHonelyEstimateRange() {
      let range = "";
      if (this.forecast && this.forecast.property_forecast) {
        if (this.forecast.property_forecast.appraisal_low) {
          range +=
            this.formatNumber(
              this.forecast.property_forecast.appraisal_low,
              true
            ) + " - ";
        }
        if (this.forecast.property_forecast.appraisal_high) {
          range += this.formatNumber(
            this.forecast.property_forecast.appraisal_high,
            true
          );
        }
      }
      return range;
    },
    getPropertyBeds() {
      let fact = "-- bds";
      if (this.forecast && this.forecast.property_forecast) {
        if (this.forecast.property_forecast.beds_count) {
          fact = this.forecast.property_forecast.beds_count + " bds";
        }
      }
      return fact;
    },
    getPropertyBaths() {
      let fact = "-- ba";
      if (this.forecast && this.forecast.property_forecast) {
        if (this.forecast.property_forecast.baths) {
          fact = this.forecast.property_forecast.baths + " ba";
        }
      }
      return fact;
    },
    getPropertySqft() {
      let fact = "-- sqft";
      if (this.forecast && this.forecast.property_forecast) {
        if (this.forecast.property_forecast.total_area_sq_ft) {
          // fact =
          //   this.formatNumber(
          //     this.forecast.property_forecast.total_area_sq_ft,
          //     false
          //   ) + " sqft";
          fact =
            this.formatSqFt(this.forecast.property_forecast.total_area_sq_ft) +
            " sqft";
        }
      }
      return fact;
    },
    chartOptions() {
      return {
        chart: {
          type: "bar",
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        chartOptions: {
          padding: {
            left: 0,
            right: 0,
          },
        },
        colors: ["#24cb43", "#ff7d1f"],
        plotOptions: {
          bar: {
            distributed: true,
            horizontal: true,
            borderRadius: 5,
          },
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        stroke: {
          show: false,
        },
        xaxis: {
          show: false,
          categories: ["Zip", "Property"],
          labels: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
        yaxis: {
          show: false,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
        grid: {
          show: false,
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        legend: {
          show: false,
        },
      };
    },
    getAvgHomesizeRange() {
      let data = "--";
      if (this.propertyZipData) {
        if (this.propertyZipData.properties_limit_range_in_sqft) {
          if (this.propertyZipData.properties_limit_range_in_sqft.lower_limit) {
            data = this.propertyZipData.properties_limit_range_in_sqft
              .lower_limit;
          } else {
            data = "--";
          }
          if (this.propertyZipData.properties_limit_range_in_sqft.upper_limit) {
            data +=
              "-" +
              this.propertyZipData.properties_limit_range_in_sqft.upper_limit;
          } else {
            data = "--";
          }
        }
      }
      return data;
    },
    getAvgValueByHomesize() {
      let data = "--";
      if (this.propertyZipData) {
        if (this.propertyZipData.average_property_value_by_homesize) {
          data = this.formatNumber(
            this.propertyZipData.average_property_value_by_homesize,
            true
          );
        }
      }
      return data;
    },
    getAvgPercentByHomesize() {
      let data = "--";
      if (this.propertyZipData) {
        if (this.propertyZipData.average_percentage_by_homesize) {
          data = this.propertyZipData.average_percentage_by_homesize + "%";
          if (
            parseFloat(this.propertyZipData.average_percentage_by_homesize) > 0
          ) {
            data += " Above";
          } else if (
            parseFloat(this.propertyZipData.average_percentage_by_homesize) < 0
          ) {
            data += " Below";
          }
        }
      }
      return data;
    },
    getAvgPropertyValueByZip() {
      let data = "--";
      if (this.propertyZipData) {
        if (this.propertyZipData.average_properties_value_by_zipcode) {
          data = this.formatNumber(
            this.propertyZipData.average_properties_value_by_zipcode,
            true
          );
        }
      }
      return data;
    },
    getPropertyPercentageByZip() {
      let data = "--";
      if (this.propertyZipData) {
        if (this.propertyZipData.property_percentage_by_zipcode) {
          data = this.propertyZipData.property_percentage_by_zipcode + "%";
          if (
            parseFloat(this.propertyZipData.property_percentage_by_zipcode) > 0
          ) {
            data += " Above";
          } else if (
            parseFloat(this.propertyZipData.property_percentage_by_zipcode) < 0
          ) {
            data += " Below";
          }
        }
      }
      return data;
    },
    getAvgValuePerSqftInZip() {
      let data = "--";
      if (this.propertyZipData) {
        if (this.propertyZipData.average_value_per_sqft_in_zipcode) {
          data = this.formatNumber(
            this.propertyZipData.average_value_per_sqft_in_zipcode,
            true
          );
        }
      }
      return data;
    },
    getPropertyPercentPerSqftInZip() {
      let data = "--";
      if (this.propertyZipData) {
        if (this.propertyZipData.property_percentage_per_sqft) {
          data = this.propertyZipData.property_percentage_per_sqft + "%";
          if (
            parseFloat(this.propertyZipData.property_percentage_per_sqft) > 0
          ) {
            data += " Above";
          } else if (
            parseFloat(this.propertyZipData.property_percentage_per_sqft) < 0
          ) {
            data += " Below";
          }
        }
      }
      return data;
    },
    getAvgPropertyValueByBeds() {
      let data = "--";
      if (this.propertyZipData) {
        if (this.propertyZipData.average_propertise_value_by_bedroom) {
          data = this.formatNumber(
            this.propertyZipData.average_propertise_value_by_bedroom,
            true
          );
        }
      }
      return data;
    },
    getPropertyPercentByBeds() {
      let data = "--";
      if (this.propertyZipData) {
        if (this.propertyZipData.property_percentage_by_bedroom) {
          data = this.propertyZipData.property_percentage_by_bedroom + "%";
          if (
            parseFloat(this.propertyZipData.property_percentage_by_bedroom) > 0
          ) {
            data += " Above";
          } else if (
            parseFloat(this.propertyZipData.property_percentage_by_bedroom) < 0
          ) {
            data += " Below";
          }
        }
      }
      return data;
    },
    propertyPercentBySizeData() {
      let data = [];
      if (this.propertyZipData) {
        if (this.propertyZipData.average_percentage_by_homesize) {
          data = [
            {
              name: "Property v. Size",
              data: [
                100,
                100 +
                  parseFloat(
                    this.propertyZipData.average_percentage_by_homesize
                  ),
              ],
            },
          ];
        }
      }
      return data;
    },
    propertyPricePerSqftData() {
      let data = [];
      if (this.propertyZipData) {
        if (this.propertyZipData.property_percentage_per_sqft) {
          data = [
            {
              name: "Price per SQFT",
              data: [
                100,
                100 +
                  parseFloat(this.propertyZipData.property_percentage_per_sqft),
              ],
            },
          ];
        }
      }
      return data;
    },
    propertyPercentByZipData() {
      let data = [];
      if (this.propertyZipData) {
        if (this.propertyZipData.property_percentage_by_zipcode) {
          data = [
            {
              name: "Property v. Properties",
              data: [
                100,
                100 +
                  parseFloat(
                    this.propertyZipData.property_percentage_by_zipcode
                  ),
              ],
            },
          ];
        }
      }
      return data;
    },
    propertyPercentByBedsData() {
      let data = [];
      if (this.propertyZipData) {
        if (this.propertyZipData.property_percentage_by_bedroom) {
          data = [
            {
              name: "Property v. Bedrooms",
              data: [
                100,
                100 +
                  parseFloat(
                    this.propertyZipData.property_percentage_by_bedroom
                  ),
              ],
            },
          ];
        }
      }
      return data;
    },
    getPropertyId() {
      return this.property?.address?.property_id;
    },
    forecastAccess() {
      return this.forecast.access;
    },
  },
  updated() {},
  watch: {
    forecast: function() {
      // do something
    },
  },
  mounted() {
    // console.log(this.forecast)
    // console.log(this.propertyZipData)
    if (this.$store.getters["auth/isCognitoUserLoggedIn"]) {
      axios
        .get("https://api.honely.com/lookup-test/user_profile", {
          params: {
            email: this.$store.getters["auth/cognitoUser"].attributes.email,
          },
        })
        .then((response) => {
          this.user = response.data;
          // this.prepopulateFields()
        })
        .catch((error) => {
          console.log("[ERROR] Failed to fetch user data", error);
          console.log(error.response.data.errorMessage);
        });
    }
    if (this.forecast && this.forecast.property_forecast) {
      this.activeForecastTimeframe = 1;
      this.activeForecastTimeframeText = this.forecastTimeframe[1];
      this.forecastPercentageChange = this.forecast.property_forecast.percentage_change_forecasts;
      this.forecastValueChange = this.forecast.property_forecast.value_change_forecasts;
      this.activeForecastStatus = this.getForecastStatus(
        this.forecastPercentageChange[this.activeForecastTimeframe].change
      );
      this.activeForecastPercent =
        this.activeForecastStatus +
        " " +
        this.getForcastPercent(
          this.forecastPercentageChange[this.activeForecastTimeframe].change
        );
      this.activeForecastValue = this.formatNumber(
        this.forecastValueChange[this.activeForecastTimeframe].change,
        true
      );
    }
    this.checkImage();
    this.getAllPropertyForecastData();
  },
  created() {
    this.isMobile = window.innerWidth < 700;
  },
  methods: {
    // getMobileImageHeight() {
    //   return window.innerWidth * 0.2;
    // },
    // getMobileImageWidth() {
    //   return window.innerWidth * 0.2 * 1.5;
    // },
    getForcastDiffDesktopVer(forecastIndex) {
      let forecastValue =
        this.forecastValueChange[forecastIndex]?.change || null;

      if (forecastValue) {
        let appraisal = this.forecast.property_forecast.appraisal;

        let diff = Math.round((forecastValue - appraisal) / 100) * 100;

        if (diff > 0) {
          return `$${diff?.toLocaleString()}`;
        } else if (diff < 0) {
          return `-$${Math.abs(diff).toLocaleString()}`;
        } else {
          return ``;
        }
      } else {
        return ``;
      }
    },
    getForecastDiffText(forecast) {
      let forecastValueStr = forecast ? forecast : "";

      if (typeof forecastValueStr === "string") {
        let forecastNumber = parseInt(forecastValueStr.replace(/\$|,/g, ""));
        let appraisal = this.forecast?.property_forecast?.appraisal ?? "--";

        let diff = forecastNumber - Math.round(appraisal / 100) * 100;

        // if postive
        if (diff > 0) {
          return { text: `$${diff?.toLocaleString()}`, color: "green" };
        } else if (diff < 0) {
          return {
            text: `-$${Math.abs(diff)?.toLocaleString()}`,
            color: "red",
          };
        } else {
          return ``;
        }
      } else {
        return 0;
      }
    },
    isGoogleImage() {
      if (!this.image.includes("/site_images")) {
        return true;
      } else {
        return false;
      }
    },
    toggleFullScreenImage() {
      if (this.isMobile) {
        this.showDialog = true;
      }
    },
    isRestrictedSection(text) {
      if (this.isCognitoUserLoggedIn) {
        if (text === "2 Years" || text === "3 Years") {
          return true;
        }
      } else {
        if (
          // text === "3 Month Honely Forecast" ||
          text === "1 Year" ||
          text === "2 Years" ||
          text === "3 Years"
        ) {
          return true;
        }
      }
      return false;
    },
    selectOption(option) {
      this.selectedForecast = option;
      this.displayPropertyForecastData(option.value);
      this.menu = false; // close the menu after selecting an option
    },
    toggleEmailPopup(val) {
      this.showEmailPopup = val;
    },
    getListhubPhotoURL(index) {
      let url = "";
      if (
        this.forecast.property_forecast.listing_key !== null &&
        this.getListhubPhotoCount > 0
      ) {
        url =
          "https://listhub-property-images.s3.amazonaws.com/" +
          this.forecast.property_forecast.listing_key +
          "_" +
          index +
          ".jpg";
      }
      return url;
    },
    goToSubscriptionPage() {
      window.location.href = "/smart-data-subscription";
    },
    checkImage() {
      const self = this;
      if (this.forecast && this.forecast.property_forecast) {
        if (
          this.forecast.property_forecast.latitude &&
          this.forecast.property_forecast.longitude
        ) {
          const url =
            "https://maps.googleapis.com/maps/api/streetview?size=600x400&location=" +
            this.forecast.property_forecast.latitude +
            "," +
            this.forecast.property_forecast.longitude +
            "&radius=100&return_error_code=true&source=outdoor&key=" +
            this.$mapsKey;
          // console.log(url)
          fetch(url, {
            method: "GET",
            headers: {},
          })
            .then(function(response) {
              // console.log(response)
              if (!response.ok) {
                throw Error(response.status);
              }
              return response.blob;
            })
            .then(function(blob) {
              self.image = url;
              self.validImage = true;
            })
            .catch((err) => {
              self.validImage = true;
              self.image = "/site_images/listing_default_image.png";
              console.log("[ERROR] Google image API failed =>", err);
            });
        }
      }
    },
    getForecastStatus(value) {
      let style = "";
      if (value) {
        if (!isNaN(value)) {
          if (parseFloat(value) > 0) {
            style = "increase";
          } else if (parseFloat(value) < 0) {
            style = "decrease";
          }
        }
      }
      return style;
    },
    getForcastPercent(value) {
      let data = "--";
      if (value) {
        data = value;
      }
      return data;
    },
    getForecastStatusArrowIcon() {
      let icon = "";
      if (this.activeForecastStatus === "increase") {
        icon = '<span class="mdi mdi-chevron-up forecast-status-up"></span>';
      } else if (this.activeForecastStatus === "decrease") {
        icon =
          '<span class="mdi mdi-chevron-down forecast-status-down"></span>';
      }
      return icon;
    },

    getAllPropertyForecastData() {
      // it is current, so it just the current estiamte value
      this.forecastDataObj[0].value = this.forecast.property_forecast.appraisal
        ? (
            Math.round(this.forecast.property_forecast.appraisal / 100) * 100
          ).toLocaleString()
        : "--";

      this.forecastDataObj[1].value = this.forecastValueChange[1].change
        ? (
            Math.round(this.forecastValueChange[1].change / 100) * 100
          ).toLocaleString()
        : "--";
      this.forecastDataObj[2].value = this.forecastValueChange[2].change
        ? (
            Math.round(this.forecastValueChange[2].change / 100) * 100
          ).toLocaleString()
        : "--";
      this.forecastDataObj[3].value = this.forecastValueChange[3].change
        ? (
            Math.round(this.forecastValueChange[3].change / 100) * 100
          ).toLocaleString()
        : "--";
      this.forecastDataObj[4].value = this.forecastValueChange[4].change
        ? (
            Math.round(this.forecastValueChange[4].change / 100) * 100
          ).toLocaleString()
        : "--";

      this.forecastDataObj[0].percentage = this.forecastPercentageChange[0]
        .change
        ? this.forecastPercentageChange[0].change
        : "--";
      this.forecastDataObj[1].percentage = this.forecastPercentageChange[1]
        .change
        ? this.forecastPercentageChange[1].change
        : "--";
      this.forecastDataObj[2].percentage = this.forecastPercentageChange[2]
        .change
        ? this.forecastPercentageChange[2].change
        : "--";
      this.forecastDataObj[3].percentage = this.forecastPercentageChange[3]
        .change
        ? this.forecastPercentageChange[3].change
        : "--";
      this.forecastDataObj[4].percentage = this.forecastPercentageChange[4]
        .change
        ? this.forecastPercentageChange[4].change
        : "--";
    },
    displayPropertyForecastData(timeframe) {
      if (this.forecastPercentageChange && this.forecastValueChange) {
        this.activeForecastStatus = this.getForecastStatus(
          this.forecastPercentageChange[timeframe].change
        );
        this.activeForecastPercent =
          this.activeForecastStatus +
          " " +
          this.getForcastPercent(
            this.forecastPercentageChange[timeframe].change
          );

        if (timeframe === 0) {
          this.activeForecastValue = this.getHonelyEstimate;
        } else {
          this.activeForecastValue = this.formatNumber(
            this.forecastValueChange[timeframe].change,
            true
          );
        }

        this.activeForecastTimeframeText = this.forecastTimeframe[timeframe];
      }
      const tabs = document.getElementsByClassName("forecast-tab");
      if (tabs) {
        for (let i = 0; i < tabs.length; i++) {
          if (i !== timeframe) {
            tabs[i].classList.remove("active");
          } else {
            tabs[i].classList.add("active");
          }
        }
      }
    },
    toggleFavorite: function(listing) {
      if (this.$store.getters["auth/isCognitoUserLoggedIn"]) {
        if (!(!this.isFavorite && this.favoriteListings.length === 5)) {
          const payload = {
            property_id: listing.address.property_id,
            apn: listing.address.apn,
            fips: listing.address.fips,
            user_id: this.user.user_id,
            address: listing.address.full_address,
            appraisal: listing.valuation.appraisal,
          };
          if (!this.isFavorite) {
            const paramsTier2 = {
              first_name: this.user.first_name,
              last_name: this.user.last_name,
              user_email: this.user.email,
              phone_number: this.user.phone_number,
              address: listing.address.full_address,
              zip_code: listing.address.zip_code,
              tier: "2",
            };
            // axios.post('https://api.honely.com/lookup-test/leads_tier_notification', paramsTier2)
          }
          this.$store.dispatch("listings/toggleFavorite", payload);
        } else {
          this.$alert("Cannot have more than 5 favorite listings.");
        }
      } else {
        this.$alert(
          "Please log in or create an account to add this to your favorites."
        );
      }
    },
    capitalize(string) {
      if (string) {
        let result = "";
        const strings = string.split(" ");
        for (let i = 0; i < strings.length; i++) {
          result +=
            strings[i].charAt(0).toUpperCase() +
            strings[i].slice(1).toLowerCase();
          if (i !== strings.length - 1) {
            result += " ";
          }
        }
        return result;
      } else {
        return string;
      }
    },
    formatNumber(num, currency) {
      if (num && num !== null) {
        if (typeof num === "number") {
          num = Math.round(num / 100) * 100;
          if (currency) {
            return num.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });
          } else {
            return num;
          }
        } else if (typeof num === "string") {
          num = Math.round(parseFloat(num) / 100) * 100;
          if (currency) {
            return parseInt(num).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });
          } else {
            return num;
          }
        }
      } else {
        return "--";
      }
    },
    formatSqFt(num) {
      if (num && num !== null && num !== "0.0" && num !== "0") {
        if (typeof num === "number") {
          num = Math.round(num / 10) * 10;
          return num;
        } else if (typeof num === "string") {
          num = Math.round(parseFloat(num) / 10) * 10;
          return num;
        }
      } else {
        return "--";
      }
    },
    showForecastReportModal() {
      if (this.subscriptionFlag) {
        document.getElementById("report-form-overlay").classList.add("active");
        window.dispatchEvent(new Event("resize"));
      } else {
        window.location.href = "/smart-data-subscription";
      }
    },
    showHonelyCalculatorModal() {
      document
        .getElementById("calculator-form-overlay")
        .classList.add("active");
    },
    showSubscriptionPopup() {
      // this.showSubscription = true
      if (this.isCognitoUserLoggedIn) {
        console.log("data is " + this.address);
        if (this.address) {
          this.$router.push({
            path: "subscribe",
            query: { address: this.address },
          });
        } else {
          // this.$router.push({ path: 'Pricing', query: { address: this.getAddr1() + this.getAddr2() } })
          this.$router.push({
            name: "Subscribe",
            query: {
              address: this.property.address.full_address,
              id: this.getPropertyId,
            },
          });
        }
      } else {
        this.showLogin = true;
      }
    },
    toggleLoginPopupShow(value) {
      this.showLogin = value;
    },
    getAddr1() {
      if (this.property) {
        if (this.property.address.full_address) {
          if (
            this.property.address.city &&
            this.property.address.state &&
            this.property.address.zip_code
          ) {
            const toRemove =
              this.capitalize(this.property.address.city) +
              " " +
              this.property.address.state +
              " " +
              this.property.address.zip_code;
            return (
              this.property.address.full_address.replace(toRemove, "").trim() +
              ", "
            );
          } else {
            return this.property.address.full_address;
          }
        } else {
          return "--";
        }
      } else {
        return "--";
      }
    },
    getAddr2() {
      let address2 = "";
      if (this.property) {
        if (this.property.address.city) {
          address2 += this.capitalize(this.property.address.city) + " ";
        }
        if (this.property.address.state) {
          address2 += this.property.address.state + " ";
        }
        if (this.property.address.zip_code) {
          address2 += this.property.address.zip_code;
        }
      }
      return address2;
    },
    showSingleSubscriptionPopup() {
      this.showSingleSubscription = true;
    },
    toggleSubscriptionShow(value) {
      this.showSubscription = value;
    },
    toggleSingleSubscriptionShow(value) {
      this.showSingleSubscription = value;
    },
  },
};
</script>

<style lang="sass">


.listing-full-screen
  background-color: rgba(24, 24, 19, 0.9) !important
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  height: 100%

.v-toolbar
  align-self: flex-end

.v-btn
  background-color: inherit

.v-sheet.v-card
  border-radius: 0px

.google-image-full-screen
  background-size: 100% !important
  height: 100%


/*-- special input that show up in v-select component, hidden it--> */
.v-select__selections
  input
    visibility: hidden
</style>
